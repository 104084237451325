/*================================================================================
	//   Item Name: Ntiva NCP Portal
	Version: 2.0
	Author: DEVNOSTIC
	Author: www.devnostic.com
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.dark-layout a {
	color: #0C5495;
}

.mobile-branding {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto 0;
	text-align: center;
	z-index: -1;
}


.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg, 
.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a i {
	width: 20px !important;
	height: 20px !important;
}

[dir] .main-menu.menu-light, [dir] .main-menu.menu-light .navigation {
	min-height: 300px !important;
}

.navigation > li > ul > div > li a svg {
	width: 18px !important;
	height: 18px !important;
	margin-left: 10px !important;
}

.nav-search {
	list-style: none !important;
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub > a::after {
	top: 7px !important;
}

[dir] .main-menu.menu-light .navigation li a {
	padding: 6px 15px 6px 15px !important;

	&.dropdown-user-link {
		padding: 6px 9px 6px 9px !important;
	}
  }

  .main-menu.menu-light .navigation li a span {
	vertical-align: middle !important;
  }

  [dir] .vertical-layout .main-menu .navigation a.active {
	box-shadow: 0 0 0px 0px rgba(12, 84, 149, 0.7) !important;
  }

  li.navigation-header.has-sub div::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 1rem;
	transform: rotate(0deg);
  }

  .auth-wrapper.auth-basic .auth-inner-md {
	max-width: 600px !important;
  }

  .desktop-logo { display: block; }
  .mobile-logo { display: none; }
  .menu-collapsed .desktop-logo {
	display: none;
  }
  .menu-collapsed .mobile-logo {
	display: block;
	max-width: 40px !important;
  }

  .text-right {
	text-align: right;
  }

  @media (min-width: 768px) {
	.faq-navigation {
		height: auto !important;
	  min-height: 200px !important;
	}
  }

  .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	height: auto !important;
  }

  .pt-5 {
	padding-top: 5.5rem !important;
  }